import {createApp} from 'vue'
import App from './App.vue'
import router from "./router"
import {Toast, Dialog, Divider, Icon,ImagePreview } from 'vant';
import 'vant/lib/index.css';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';

createApp(App)
    .use(router)
    .use(Toast)
    .use(Dialog)
    .use(Divider)
    .use(Icon)
    .use(ImagePreview)
    .mount('#app')
