import {createRouter, createWebHashHistory} from "vue-router"

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import('../layout/index.vue'),
        redirect: '/index',
        children: [
            {
                path: 'index',
                component: () => import("../pages/home/index.vue")
            }
        ]
    },
    {
        path: "/saved",
        name: "saved",
        component: () => import('../layout/index.vue'),
        redirect: '/saved/index',
        children: [
            {
                path: 'index',
                component: () => import("../pages/saved/index.vue")
            }
        ]
    },
    {
        path: "/socialCircel",
        name: "socialCircel",
        component: () => import('../layout/index.vue'),
        redirect: '/socialCircel/index',
        children: [
            {
                path: 'index',
                component: () => import("../pages/socialCircel/index.vue")
            }
        ]
    },
    {
        path: "/mine",
        name: "mine",
        component: () => import('../layout/index.vue'),
        redirect: '/mine/index',
        children: [
            {
                path: 'index',
                component: () => import("../pages/mine/index.vue")
            }
        ]
    },
    {
        path: "/detail",
        name: "detail",
        component: () => import("../pages/detail/index.vue")
    },
    {
        path: "/invite",
        name: "invite",
        component: () => import("../pages/mine/invite.vue")
    },
    {
        path: "/publish",
        name: "publish",
        component: () => import("../pages/socialCircel/publish/index.vue")
    },
    {
        path: "/socialCircelDetail",
        name: "socialCircelDetail",
        component: () => import("../pages/socialCircel/detail/index.vue")
    },
    {
        path: "/profile",
        name: "profile",
        component: () => import("../pages/mine/profile.vue")
    },
    {
        path: "/nickname-edit",
        name: "nickname-edit",
        component: () => import("../pages/mine/nickname-edit.vue")
    },
    {
        path: "/email-edit",
        name: "email-edit",
        component: () => import("../pages/mine/email-edit.vue")
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../pages/login/index.vue")
    },
    {
        path: "/register",
        name: "register",
        component: () => import("../pages/register/index.vue")
    },
    {
        path: "/messages",
        name: "messages",
        component: () => import("../pages/message/list.vue")
    },
    {
        path: "/message-detail",
        name: "message-detail",
        component: () => import("../pages/message/messageDetail.vue")
    }
]
export default createRouter({
    //路由规则
    history: createWebHashHistory(),
    routes
})
